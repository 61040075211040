/*
  Global css imports
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .play-slider-animation {
    animation-play-state: running;
  }
  .pause-slider-animation {
    animation-play-state: paused;
  }
  .pause {
    animation-play-state: paused;
  }
}

/*
  Font Montserrat, Inter and Syne
*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Syne:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins', 'Montserrat' ;
  /* overflow-x: hidden; */
}

.dp h1 {
  font-size: 2.125rem;
  font-weight: bold;
}

.dp h2 {
  font-size: 1.875rem;
  font-weight: bold;

}

.dp h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.dp ul {
  list-style: disc;
  margin-left: 10px;
}

.dp ul li {
  margin: 20px;
}