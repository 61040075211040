/*
   Override Ant Design Primary Button
*/


.ant-btn-primary {
    font-family: 'Syne', sans-serif;
    background-color: #000000;
}

.ant-btn-primary:hover {
    background-color: #000000 !important;
    color: #FFFFFF !important;
}

.ant-btn span {
    padding: 2px 0;
    font-weight: 500;
}

/* ant button with icons */
.ant-btn-ghost span img {
    margin-left: 20px;
}

/* Overriding antd tabs */

/* For the tab in fees and commissions section*/
.horizontal-tab .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: #000000;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    font-family: 'Syne', sans-serif;
}

.horizontal-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    border-bottom: 4px solid #000000;
    color: #000000;
}

/* For the tab in faqs page */

.faq-tab .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
}

.faq-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span {
    border-left: 3px solid #000000;
    color:  rgba(0, 0, 0, 0.6) !important;
    padding: 0px 10px;
}
/* .faq-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(1) .ant-tabs-tab-btn, .faq-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(7) .ant-tabs-tab-btn {
    border: none;
    padding: 0px;
} */

@media (max-width: 767px) {
    .faq-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        border-left: none;
        border-bottom: 3px solid #000000;
        padding: 10px 0px;
    }
}
.ant-tabs-left >.ant-tabs-content-holder {
    border: none;
}

.ant-tabs-left >.ant-tabs-nav .ant-tabs-tab {
    padding: 0;
}

/* For all tab components */

.ant-tabs .ant-tabs-ink-bar {
    display: none;
}

.ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-left >.ant-tabs-nav::before {
    border: none;
}
.ant-tabs-nav-wrap::before {
    display: none;
}
.ant-tabs-nav-list {
     margin: auto;
}

@media (min-width: 1410px) {
    .horizontal-tab .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        padding: 12px 25px;
    }
}

@media (max-width: 767px) {
    .horizontal-tab .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 20px;
}
}

/* Overriding ant collapse component */

.ant-collapse{
    width: 100%;
}

.ant-collapse-expand-icon {
    transform: rotate(90deg);
}

.ant-collapse-item-active .ant-collapse-expand-icon {
    transform: rotate(180deg);
}

 .faq-collapse>.ant-collapse-item >.ant-collapse-header >.ant-collapse-header-text {
    text-align: left;
    font-size: 24px;
}

@media (max-width: 767px) {
    .faq-collapse>.ant-collapse-item >.ant-collapse-header >.ant-collapse-header-text  {
        font-size: 20px;
    }
    .faq-collapse>.ant-collapse-item >.ant-collapse-header {
        padding: 12px 0;
    }
    .faq-collapse .ant-collapse-content>.ant-collapse-content-box {
        padding: 16px 0;
    }
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    font-weight: normal;
    line-height: 32px;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    flex-shrink: 0;
}

/*
   Override Ant Design Ghost Button
*/

.ant-btn-ghost {
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #000000;
}

.ant-btn-ghost span {
    white-space: nowrap;
}
/* Override antd modal */

.ant-modal {
    max-width: 100vw;
    z-index: 1000;
  }

  .ant-modal .ant-modal-content {
    height: 100vh;
    border-radius: 0;
    padding: 0;
    z-index: -1;
  }

  .ant-modal-wrap {
    overflow-x: hidden !important;
  }

  /* Classes to distinguish the increasing and decreasing indices in the slider */

  .ant-modal .ant-modal-content .red{
    background-color: #FF452C;
  }

  .ant-modal .ant-modal-content .green {
    background-color: #5CAD00;
  }

  .red .ant-modal .ant-modal-content {
    background-color: #FF452C;
  }

  .blue .ant-modal .ant-modal-content {
    background-color: #0855CC;
  }

  .green .ant-modal .ant-modal-content {
    background-color: #5CAD00;
  }

.recharts-tooltip-wrapper {
    z-index: 2;
}

/* Overriding antd pagination */

.ant-pagination .ant-pagination-item {
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    font-family: 'Roboto';
}

.ant-pagination .ant-pagination-item-active {
    border: none;
}

.ant-pagination .ant-pagination-item-active a, .ant-pagination .ant-pagination-item-active:hover a {
    color: rgba(0, 0, 0, 0.85);
}

/* For the ticker page tabs  */

.ticker-tab .ant-tabs-left >.ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    margin-bottom: 10px;
    font-family:'Roboto', sans-serif;
    color: #000000;
}

.ticker-tab .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    padding: 4px 10px;
    width: 100%;
}
.ticker-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFFFFF;
    background-color: #000000;
    text-transform: uppercase;
    border-radius: 6px 0px 0px 6px;
}

@media (max-width: 1023px) {
    .ticker-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        border-radius: 6px;
    }
}
/* overriding antd link button */

.ant-btn-link {
    padding: 0;
    color: #000000;
}

.ant-btn-link:hover > span {
    color: #9ca3af
}

.our-mission-grid::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

.our-mission-grid::-webkit-scrollbar-thumb {
    background: #D3D3D3;
    border-radius: 3px;
}

.fees-and-commission-contents ul li {
    padding: 6px 0;
}

/* .fees-and-commission-contents p::first-letter {
    font-size: 72px;
} */

/* 
Overriding our features Carousel section active buttons style
*/
/* .slick-dots li button {
    background-color: rgb(220, 226, 231) !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slick-dots li.slick-active button {
    background: #5CAD00 !important;
} */

/* .ant-carousel .slick-dots-bottom {
    bottom: -72px;
} */

/* @media (max-width: 640px) {
    .ant-carousel .slick-dots-bottom {
        bottom: -20px;
    }
} */
